<template>
  <cv-modal
    ref="modal"
    :auto-hide-off="false"
    :primary-button-disabled="isSubmitDisabled"
    @primary-click="save"
  >
    <template slot="title">
      Add stock split
    </template>
    <template slot="content">
      <div class="split__options">
        <cv-date-picker
          kind="single"
          date-label="Date"
          :cal-options="{ dateFormat: 'Y-m-d' }"
          v-model="date"
        />

        <cv-number-input
          label="Split"
          v-model="split"
        />
      </div>
    </template>
    <template slot="primary-button">
      Save
    </template>
  </cv-modal>
</template>

<script>
import { CvNumberInput, CvDatePicker, CvModal } from '@carbon/vue';
import { addStockSplit } from '@/lib/api';

export default {
  name: 'Split',
  components: {
    CvNumberInput,
    CvDatePicker,
    CvModal,
  },

  props: {
    assetId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      date: '',
      split: 0,
      isSaving: false,
    };
  },

  computed: {
    isSubmitDisabled() {
      return !this.date || this.split <= 0 || this.isSaving;
    },
  },

  methods: {
    show() {
      this.reset();
      this.$refs.modal.show();
    },

    reset() {
      this.date = '';
      this.split = 0;
      this.isSaving = false;
    },

    async save() {
      this.isSaving = true;
      try {
        const preset = await addStockSplit({
          assetId: this.assetId,
          split: this.split,
          date: new Date(`${this.date}T00:00:00Z`),
        });

        this.$store.dispatch('stockSplits/add', [preset]);
        this.$store.dispatch('ledger/calculate');
        this.$toasts.success('Added split');
        this.$refs.modal.hide();
      } catch (error) {
        this.$toasts.error('Failed to save transaction', { subTitle: error.message });
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style>
.split__options > * {
  margin: 0 0 2rem 0;
  max-width: 10rem;
}

.asset-editor__input:last-child {
  margin: 0;
}
</style>
