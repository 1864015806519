<template>
  <div class="asset-page">
    <div class="content-header">
      <h1>{{ asset.name }} ({{ asset.symbol }})</h1>
    </div>

    <div class="asset-page__content">
      <div class="asset-page__tiles">
        <cv-tile>
          <h3>Cost basis</h3>
          <p>{{ formatCurrency(ledgerEntry.costOfSharesLeft) }}</p>
        </cv-tile>

        <cv-tile>
          <h3>Quantity</h3>
          <p>{{ formatFloat(ledgerEntry.sharesLeft) }}</p>
        </cv-tile>

        <cv-tile>
          <h3>Average price</h3>
          <p>
            {{
              formatCurrency(
                ledgerEntry.costOfSharesLeft / ledgerEntry.sharesLeft
              )
            }}
          </p>
        </cv-tile>

        <cv-tile>
          <h3>Current price</h3>
          <p v-if="currentPriceLocal">
            {{ formatCurrency(currentPriceLocal) }}
            (<span
              :class="{
                'asset-price-positive': percentIncrease > 0,
                'asset-price': true,
              }"
            >{{ percentIncrease > 0 ? "+" : "" }}{{ percentIncrease }}%</span>)
          </p>
          <cv-skeleton-text v-if="!currentPriceLocal" />
        </cv-tile>
      </div>

      <h2>Stock splits</h2>
      <cv-structured-list
        :condensed="false"
        class="asset-page__splits"
        v-if="splits.length"
      >
        <template slot="headings">
          <cv-structured-list-heading>Date</cv-structured-list-heading>
          <cv-structured-list-heading>Split</cv-structured-list-heading>
        </template>
        <template slot="items">
          <cv-structured-list-item
            v-for="(item, rowIndex) in splits"
            :key="`${rowIndex}`"
            :value="`${rowIndex}`"
          >
            <cv-structured-list-data>{{ formatDate(item.date) }}</cv-structured-list-data>
            <cv-structured-list-data>{{ item.split }}:1</cv-structured-list-data>
          </cv-structured-list-item>
        </template>
      </cv-structured-list>
      <cv-button @click="addSplitModal">
        Add
      </cv-button>

      <h2>Transactions</h2>

      <transactions-table
        :data="transactions"
        :table-actions-enabled="true"
        :should-show-import-button="false"
        @add="addTransaction"
        :remove-columns="[2]"
      />
    </div>

    <split
      ref="splitModal"
      :asset-id="id"
    />
  </div>
</template>

<script>
import {
  CvTile,
  CvSkeletonText,
  CvButton,
  CvStructuredList,
  CvStructuredListItem,
  CvStructuredListData,
  CvStructuredListHeading,
} from '@carbon/vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
import TransactionsTable from '../Transactions/TransactionsTable.vue';
import {
  formatCurrency, formatFloat, formatDate, fx,
} from '../../lib/utils';
import Split from './Split.vue';
// id: this.assetId,
// profit: this.getProfit(),
// sharesLeft: this.getNumSharesLeft(),
// costOfSharesLeft: this.getCostOfSharesLeft(),
// transactions: this.transactions.map((transaction) => transaction.toJSON()),

export default {
  name: 'Asset',

  components: {
    CvTile,
    TransactionsTable,
    CvSkeletonText,
    CvButton,
    CvStructuredList,
    CvStructuredListItem,
    CvStructuredListData,
    CvStructuredListHeading,
    Split,
  },

  data() {
    return {
      currentPrice: 0,
      currentPriceCurrency: null,
      currentPriceLocal: 0,
    };
  },

  computed: {
    ...mapGetters({
      getAsset: 'assets/get',
      getLedgerEntry: 'ledger/getAsset',
      allSplits: 'stockSplits/getAll',
    }),

    id() {
      return this.$route.params.id;
    },

    asset() {
      return this.getAsset(this.id);
    },

    ledgerEntry() {
      return this.getLedgerEntry(this.id);
    },

    costBasis() {
      return this.ledgerEntry.costOfSharesLeft;
    },

    transactions() {
      return this.ledgerEntry.transactions;
    },

    splits() {
      return this.allSplits.filter(({ assetId }) => assetId === this.id);
    },

    averagePrice() {
      return this.ledgerEntry.costOfSharesLeft / this.ledgerEntry.sharesLeft;
    },

    percentIncrease() {
      return (
        ((this.currentPriceLocal - this.averagePrice) / this.averagePrice)
        * 100
      ).toFixed(2);
    },
  },

  methods: {
    formatCurrency,
    formatFloat,
    formatDate,

    addSplitModal() {
      this.$refs.splitModal.show();
    },

    addTransaction() {
      this.$router.push(`/assets/${this.id}/transactions/add`);
    },
  },

  async mounted() {
    const { data } = await axios.get(
      `https://stock-data-sigma.vercel.app/api?module=quote&query=${this.asset.symbol}`,
    );

    this.currentPriceLocal = fx(data.regularMarketOpen, data.currency, 'EUR');
  },
};
</script>

<style lang="css">
.asset-page__content > * {
  margin-bottom: 2rem;
}

.asset-page__tiles {
  display: flex;
  gap: 1rem;
}

.asset-price {
  color: red;
}

.asset-price-positive {
  color: green;
}

.asset-page__splits {
  width: 20rem;
}
</style>
